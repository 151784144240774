<template>
  <div>
    <div class="form-bg-box uf uf-ver uf-pc uf-ac">
      <div class="form-wrap">
        <div style="margin-top: -20px;margin-bottom: -10px;font-weight: bold;font-size: 18px;">
          <span>企业景气监测预警</span>
        </div>
        <el-divider></el-divider>
        <el-form :model="user" ref="user" :rules="dataRule" style="margin-left: 15px;margin-right: 15px;"
                 label-width="100px">
          <el-row :gutter="20" style="width: 100%">
            <el-col :span="8">
              <el-form-item prop="model" label="分析模型：">
                <el-select v-model="user.model" placeholder="分析模型" style="width: 100%;">
                  <el-option
                      v-for="item in modelOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="source" label="数据来源：">
                <el-select v-model="user.source" placeholder="分析数据来源" style="width: 100%;" :readonly="old">
                  <el-option
                      v-for="item in sourceOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="enterpriseName" label="公司名称：">
                <el-input
                        v-model="user.enterpriseName"
                        placeholder="公司名称"
                        style="width: 100%;"
                        :readonly="old"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" style="width: 100%">
            <el-col :span="8">
              <el-form-item prop="name" label="联系人：">
                <el-input v-model="user.name" placeholder="联系人" style="width: 100%;" :readonly="old"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="mobile" label="手机号：">
                <el-input
                    v-model="user.mobile"
                    placeholder="手机号"
                    style="width: 100%;"
                    :readonly="old"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div>
            <el-checkbox class="wi100" style="color: #000000; text-align: center;margin-bottom: 5px" v-model="agree">
              同意分析企业数据
            </el-checkbox>
            <div class="tac wi100">
              <el-button
                  class="login-btn wi50"
                  type="primary"
                  @click="showJingQi"
                  :loading="loading"
                  :disabled="!agree"
              >
                提交
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <el-dialog
            title="企业景气状态区间"
            append-to-body
            :visible.sync="visible"
            width="35%"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            @close="closeCallback">
      <div style="width: 100%;margin: 0 auto">
        <el-row>
          <el-col :span="18">
            <el-row>
              <div style="font-size: 240px;text-align: center;margin-bottom: -50px">
              <icon-svg name="green" class="icon-svg"></icon-svg>
            </div>
            </el-row>
            <el-row>
              <div style="width: 240px;height: 90px;margin: 0 auto">
                <el-card shadow="never" style="width: 100%;height: 100%;background-color: #67C23A">
                  <div class="area">
                    稳定区
                  </div>
                </el-card>
              </div>
            </el-row>
          </el-col>
          <el-col :span="6">
            <div style="margin-top: 50px">
              <el-row>
                <el-card shadow="never" style="width: 100%;height: 60px;background-color: #F56C6C">
                  <div class="area1">
                    危险区
                  </div>
                </el-card>
              </el-row>
              <el-row>
                <el-card shadow="never" style="width: 100%;height: 60px;background-color: #E6A23C">
                  <div class="area1">
                    警告区
                  </div>
                </el-card>
              </el-row>
              <el-row>
                <el-card shadow="never" style="width: 100%;height: 60px;background-color: #67C23A">
                  <div class="area1">
                    稳定区
                  </div>
                </el-card>
              </el-row>
              <el-row>
                <el-card shadow="never" style="width: 100%;height: 60px;background-color: #79e9ff">
                  <div class="area1">
                    警告区
                  </div>
                </el-card>
              </el-row>
              <el-row>
                <el-card shadow="never" style="width: 100%;height: 60px;background-color: #409EFF">
                  <div class="area1">
                    极端区
                  </div>
                </el-card>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
<!--      <div style="width: 100%;text-align: center;margin-top: 10px;">-->
<!--        <el-button type="primary">-->
<!--          确定-->
<!--        </el-button>-->
<!--      </div>-->
    </el-dialog>
  </div>

</template>
<script>
  import {random, encode64, sortJson, checkIsNull} from '../../../utils'
import {isMobile} from '../../../utils/validate'
import * as echarts from 'echarts'
import 'echarts-wordcloud/dist/echarts-wordcloud'
import 'echarts-wordcloud/dist/echarts-wordcloud.min'

export default {
  name: 'recommend-result-word2vec',
  components: {},
  computed: {},
  data() {
    const checkMobile = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入手机号码'))
      } else if (!isMobile(value)) {
        return callback(
            new Error('手机号码格式不正确')
        )
      } else {
        callback()
      }
    }
    return {
      old: false,
      doing: false,
      posCounts: '', //积极评论数
      negCounts: '', //消极评论数
      negWords: [],  //词云图
      histogramLabel: [],
      histogramData: [],
      linexKey: [],
      linePos: [],
      lineNeg: [],
      user: {
        model: '',     //模型
        source: '',    //数据来源
        category: '',  //产品类别
        brand: '',      //产品品牌
        product: '',      //具体型号
        name: '',
        enterpriseName: '',
        mobile: ''
      },
      dataRule: {
        model: [
          {required: true, message: '模型不能为空', trigger: 'blur'}
        ],
        source: [
          {required: true, message: '数据来源不能为空', trigger: 'blur'}
        ],
        name: [{required: true, message: '姓名不能为空', trigger: 'blur'}],
        enterpriseName: [
          {required: true, message: '公司名称不能为空', trigger: 'blur'}
        ],
        mobile: [{required: true, validator: checkMobile, trigger: 'change'}]
        // smsCode: [{ validator: checkSmsCode, trigger: 'blur' }]
      },
      modelOptions: [{
        value: 'word2vec-lstm',
        label: 'word2vec - lstm'
      }, {
        value: 'word2vec-text_cnn',
        label: 'word2vec - text_cnn'
      }],
      sourceOptions: [{
        value: 'jd',
        label: '京东商城'
      }, {
        value: 'tb',
        label: '淘宝网'
      }],
      loading: false,
      agree: false,
      visible: false,
      isMobile: false,
      result: false,
      smsTime: '',
      type: 'TJS',
      timer: new Date().getTime(),
      reportPath: '',
      companyInfoList: {},
      recommendList: {},
      userInfoList: {},
      corporatePortraitList: [],
      fileList: [],
      sbrzServiceList: [],
      mainId: '',
      curTabIndex: 0,
      tabs: [
        {
          name: '企业质量安全监测',
          icon: 'zhilianganquan'
        }
      ],
    }
  },
  mounted() {

  },
  created() {
    // this.getNearest()
  },
  methods: {
    // 获取数据列表
    getNearest() {
      this.$http({
        url: this.$http.adornUrl(this.$api.TECH.WORD2VEC.NEAREST),
        method: 'get',
        params: this.$http.adornParams({})
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (data.data.status === 1) {
            this.old = true
            let model = this.modelOptions.find(item => {
              return item.value = data.data.model
            })
            this.user.model = model.label
            let source = this.sourceOptions.find(item => {
              return item.value = data.data.source
            })
            this.user.source = source.label
            this.user.category = data.data.category
            this.user.brand = data.data.brand
            this.user.product = data.data.product
            this.user.name = data.data.name
            this.user.enterpriseName = data.data.enterpriseName
            this.user.mobile = data.data.mobile
            this.user.enterpriseId = data.data.enterpriseId

            let result = JSON.parse(data.data.result)
            this.posCounts = result.pos_attr_count
            this.negCounts = result.neg_attr_count
            this.negWords = eval(result.neg_words)
            this.histogramData = eval(result.word_percent)
            this.histogramData.forEach((item, index) => {
              if (index < 7) {
                this.histogramLabel.push(item.groupId)
              }
            })

            let pos = sortJson(eval('(' + result.pos_months + ')'))
            let neg = sortJson(eval('(' + result.neg_months + ')'))
            for (var key in neg) {
              this.linexKey.push(key)
            }
            this.linexKey.forEach((item, index) => {
              if (index < 7) {
                this.linePos.push(pos[item])
                this.lineNeg.push(neg[item])
              }
            })
            this.$nextTick(() => {
              this.histogram()
              this.getResult()
            })

          }
          if (data.data.status === 0) {
            this.doing = true
            let model = this.modelOptions.find(item => {
              return item.value = data.data.model
            })
            this.user.model = model.label
            let source = this.sourceOptions.find(item => {
              return item.value = data.data.source
            })
            this.user.source = source.label
            this.user.category = data.data.category
            this.user.brand = data.data.brand
            this.user.product = data.data.product
            this.user.name = data.data.name
            this.user.enterpriseName = data.data.enterpriseName
            this.user.mobile = data.data.mobile
          }
        }
      })
    },
    getResult() {
      this.$http({
        url: this.$http.adornUrl(this.$api.RECOMMENDED.SUBMIT),
        method: 'POST',
        params: this.$http.adornParams({
          mobile: this.user.mobile,
          companyName: this.user.enterpriseName,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          if (data.dataMap !== undefined) {
            this.recommendService = data.dataMap.recommendService
            this.mainId = data.dataMap.recommend.id
            this.userInfoList = data.dataMap.userInfoList
            this.getStats()
          }
        }
      })
    },
    // 推荐算法 进程
    getStats() {
      this.$http({
        url: this.$http.adornUrl(this.$api.RECOMMENDED.STATS),
        method: 'post',
        params: this.$http.adornParams({
          mobile: this.user.mobile
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          let mobile = this.user.mobile
          let type = data[mobile]
          if (type === 'CHECK_ING') {
            this.getStats()
          } else {
            this.toResult()
          }
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    // 推荐算法弹框
    toResult() {
      let list = [
        this.user.enterpriseName,
        this.user.mobile,
        this.mainId,
        this.user.enterpriseId
      ]
      this.corporatePortraitList = list
      this.getList()
    },
    // 获取企业画像接口
    getList() {
      let dataList = this.corporatePortraitList
      // if(dataList.length!== 0){
      //    dataList =  dataList.split(',')
      //
      // }else{
      //   dataList = []
      // }
      this.$http({
        url: this.$http.adornUrl(this.$api.RECOMMENDED.LIST),
        method: 'post',
        params: this.$http.adornParams({
          mobile: dataList.length > 0 ? dataList[1]: null,
          mainId: dataList.length > 0 ? dataList[2]: null,
          enterpriseId: dataList.length > 0 ? dataList[3]: null,
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let enterpriseLabel =data.dataList.enterpriseLabel.slice(0, 4)
          let recommendService =data.dataList.recommendService
          this.sbrzServiceList = recommendService
          this.sbrzServiceList.forEach(item => {
            if (checkIsNull(item.minPrice)) {
              let min = item.skuList[0].price
              item.skuList.forEach(sku => {
                if(sku.price < min){
                  min = sku.price
                }
              })
              item.minPrice = min
            }
          })
          this.sbrzServiceList.forEach(item=>{
            item.id=item.serviceId
          })
          this.companyLabels = []
          enterpriseLabel.forEach((item, index)=>{
            if (index < 4) {
              this.companyLabels.push(item.labelName)
            }
          })
          this.loading = false
        } else {
          this.$message.error(data.msg)
          this.loading = false
        }
      })
    },
    //质量安全监测报告
    saveTechWord2vec() {
      this.$refs['user'].validate(valid => {
        if (valid && !this.loading) {
          // this.loading=true
          this.$http({
            url: this.$http.adornUrl(this.$api.TECH.WORD2VEC.SAVE),
            method: 'POST',
            data: this.$http.adornData(this.user)
          }).then(({data}) => {
            if (data && data.code === 0) {
              if (data.status === 0) {
                this.$confirm('任务已生成，请前往个人中心查看报告生成进度...', '提示', {
                  confirmButtonText: '确定',
                  type: 'warning'
                }).then(() => {
                  this.$router.push({name: 'word2vec'})
                })
              }
              if (data.status === 1) {
                this.$confirm('报告尚未过期，前往查看', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.$router.push({
                    name: 'word2vec', params: {
                      model: this.user.model ? this.user.model : null,
                      source: this.user.source ? this.user.source : null,
                      category: this.user.category ? this.user.category : null,
                      brand: this.user.brand ? this.user.brand : null,
                      product: this.user.product ? this.user.product : null
                    }
                  })
                })
              }
            }
          })
        }
      })
    },
    // 提交按钮
    doSub() {
      this.$refs['user'].validate(valid => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(this.$api.RECOMMENDED.SUBMIT),
            method: 'POST',
            params: this.$http.adornParams({
              mobile: this.user.mobile,
              companyName: this.user.enterpriseName,
              smsCode: encode64(this.user.smsCode)
            })
          }).then(({data}) => {
            this.loading = false
            if (data && data.code === 0) {
              if (data.slideCheck === 'failed') {
                this.timer = new Date().getTime()
                this.$message.error('请拖动滑块进行验证')
              }
              if (data.dataMap !== undefined) {
                let companyRegister = data.dataMap.companyRegister
                let userRegister = data.dataMap.userRegister
                let firstRecommend = data.dataMap.firstRecommend
                this.recommendService = data.dataMap.recommendService
                this.companyInfoList = data.dataMap.companyInfo
                this.recommendList = data.dataMap.recommend
                this.userInfoList = data.dataMap.userInfoList
                // 手机号注册过 公司认证过
                if (
                    (companyRegister === '1' && userRegister === '1') ||
                    this.recommendService === '1'
                ) {
                  this.getStats()
                }
                // （手机号第二次使用） 手机号没有注册过 但已经进行过推荐
                if (firstRecommend === '0') {
                  this.timer = new Date().getTime()
                  this.$message.warning(
                      '已经进行过推荐，如需再次推荐请前往注册'
                  )
                }
                // 用户未注册 公司未注册
                if (
                    companyRegister === '0' &&
                    userRegister === '0' &&
                    firstRecommend === '1'
                ) {
                  this.timer = new Date().getTime()
                  this.$message.warning('用户未注册，企业未注册')
                }
                // 用户已注册 企业未收录
                if (
                    firstRecommend === '1' &&
                    userRegister === '1' &&
                    companyRegister === '0'
                ) {
                  this.timer = new Date().getTime()
                  this.$message.warning(
                      '当前企业未收录，如想获取更多，请去认证注册'
                  )
                }
                // （手机号第一次使用）用户未注册 公司已注册
                if (userRegister === '0' && companyRegister === '1') {
                  this.getStats()
                }
                // 手机号没注册
                if (
                    firstRecommend === '0' &&
                    userRegister === '0' &&
                    companyRegister === '1'
                ) {
                  this.timer = new Date().getTime()
                  this.$message.warning('当前手机号没有进行注册，请注册后重试')
                }
                if (
                    firstRecommend === '0' &&
                    userRegister === '1' &&
                    companyRegister === '0·'
                ) {
                  this.timer = new Date().getTime()
                  this.$message.warning(
                      '已经进行过推荐，如需再次推荐请前往注册'
                  )
                }
                // 手机号注册过 公司注册过
                if (userRegister === '1' && companyRegister === '1') {
                  this.getStats()
                }
              }
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    },
    goDetail(id) {
      this.$router.push({name: 'service', query: {id, isProvider: false}})
    },
    histogram() {
      let myChart1 = echarts.init(document.getElementById('histogram'), 'light')

      // 绘制图表
      myChart1.setOption({
        title: {
          text: '消极评论关键词占比',
          left: 'left'
        },
        xAxis: {
          data: this.histogramLabel
        },
        yAxis: {
          type: 'value'
        },
        dataGroupId: '',
        animationDurationUpdate: 500,
        series: {
          type: 'bar',
          id: 'sales',
          label: {
            normal: {
              show: true,
              position: 'insideRight',
              formatter: '{c}%'
            }
          },
          data: this.histogramData,
          universalTransition: {
            enabled: true,
            divideShape: 'clone'
          }
        }
      })

      let myChart2 = echarts.init(document.getElementById('referer'), 'light')
      myChart2.setOption({
        title: {
          text: '评论两极占比',
          left: 'left'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'right'
        },
        series: [
          {
            name: '评论数',
            type: 'pie',
            radius: '50%',
            data: [
              {value: this.posCounts, name: '积极评论'},
              {value: this.negCounts, name: '消极评论'}
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })

      let myChart3 = echarts.init(document.getElementById('line'), 'light')
      myChart3.setOption({
        title: {
          text: '两极评论走势曲线图'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['消极评论', '积极评论']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.linexKey
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '消极评论',
            type: 'line',
            stack: 'Total',
            data: this.lineNeg
          },
          {
            name: '积极评论',
            type: 'line',
            stack: 'Total',
            data: this.linePos
          }
        ]
      })

      let myChart4 = echarts.init(document.getElementById('cloud'))
      var maskImage = new Image()
      maskImage.src = require('@/assets/img/dog.png')
      myChart4.setOption({
        title: {
          text: '消极评论关键词云图',
          left: 'left'
        },
        backgroundColor: '#fff',
        tooltip: {
          show: false
        },
        series: [{
          type: 'wordCloud',
          gridSize: 1,
          sizeRange: [12, 55],
          rotationRange: [-45, 0, 45, 90],
          imageShape: maskImage,
          textStyle: {
            normal: {
              color: function () {
                return 'rgb(' +
                    Math.round(Math.random() * 255) +
                    ', ' + Math.round(Math.random() * 255) +
                    ', ' + Math.round(Math.random() * 255) + ')'
              }
            }
          },
          left: 'center',
          top: 'center',
          // width: '96%',
          // height: '100%',
          right: null,
          bottom: null,
          // width: 300,
          // height: 200,
          // top: 20,
          data: this.negWords
        }]
      })
    },
    showJingQi() {
      this.$refs['user'].validate(valid => {
        if (valid) {
          this.visible = true
        }
      })
    },
    closeCallback() {
      this.visible = false
    },
    newWord2Vec() {
      this.old = false
      this.doing = false
      this.user = {
        model: '',     //模型
        source: '',    //数据来源
        category: '',  //产品类别
        brand: '',      //产品品牌
        product: '',      //具体型号
        name: '',
        enterpriseName: '',
        mobile: ''
      }
    }
  },
  watch: {},
}
</script>
<style scoped>
a {
  color: #409eff;
}

.form-bg-box {
  width: 100%;
  min-height: calc(100vh - 150px);
  padding: 1vw;
  background: url("../../../../src/assets/img/zldoc.jpg") no-repeat;
  /*background: linear-gradient(30deg, #4bd8ff, #2afdff)*/
  /*background-position: center center;*/
  background-size: cover;
}

.form-wrap {
  width: 90vw;
  /*margin: 0 auto;*/
  padding: 2vw;
  background: rgba(255, 255, 255, .9);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border-radius: 14px;
  box-shadow: 0 0 36px rgba(2, 70, 172, .3);
}

.text-over {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#recommend-result {
  height: 100vh;
  width: 98vw;
  padding: 20px;
  margin-top: 2vh;
  /*margin: 1vh auto 0 auto;*/
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  border-top: 1px solid #e8e8e8;
  position: relative;
}

.result-dialog >>> .el-dialog {
  margin: 0 auto;
}

#recommend-result .res-left {
  width: 40%;
  background: #FFFFFF;
}

#recommend-result .res-right {
  background: #FFFFFF;
}

#recommend-result .res-left .detail-info {
  padding: 10px;
  background: rgba(231, 243, 255, .6);
  font-size: 15px;
}

#recommend-result .res-left .detail-info > div {
  /*flex: 1;*/
  min-width: 200px;
  padding: 4px 8px;
  line-height: 26px;
}

.detail-info .address {
  width: 65%;
}

.labels-box {
  min-height: 140px;
}

.labels-box .l-wrap {
  padding-top: 30px;
}

.labels-box .label-li {
  position: relative;
}

.labels-box .label {
  width: 66px;
  height: 66px;
  font-weight: bold;
  line-height: 20px;
  padding: 16px;
  text-align: center;
  font-size: 14px;
  border-radius: 50%;
  color: #FFFFFF;
  animation: labels 4s linear infinite;
  position: absolute;
  /*top: 0;*/
  left: -30px;
}

.labels-box .label-li:nth-child(1) .label {
  background: linear-gradient(30deg, #3C8CE7, #00EAFF);
  box-shadow: 0 0 26px rgba(2, 70, 172, .3);
  transform: scale(1.2);
  animation-delay: 0s;
}

.labels-box .label-li:nth-child(2) .label {
  background: linear-gradient(30deg, #c509fd, #b28eff);
  box-shadow: 0 0 26px rgba(105, 0, 140, 0.3);
  transform: scale(.9);
  animation-delay: 3s;
}

.labels-box .label-li:nth-child(3) .label {
  background: linear-gradient(30deg, #69FF97, #00E4FF);
  box-shadow: 0 0 26px rgba(0, 138, 100, 0.3);
  transform: scale(1.1);
  animation-delay: 1s;
}

.labels-box .label-li:nth-child(4) .label {
  background: linear-gradient(30deg, #FFE985, #FA742B);
  box-shadow: 0 0 26px rgba(138, 27, 0, 0.3);
  transform: scale(.82);
  animation-delay: 1.8s;
}

.labels-box .label-li:nth-child(5) .label {
  background: linear-gradient(30deg, #00c3e3, #af9aff);
  box-shadow: 0 0 26px rgba(0, 108, 138, 0.3);
  transform: scale(1);
  animation-delay: 2.4s;
}

.tab-wrap {
  padding-bottom: 15px;

}

.tab-wrap .tab-box {
  height: 50%;
  width: 100px;
  border: 1px solid #e6e6e6;
  border-left: 0;
  /*background: #f7f7f7;*/
  /*background: linear-gradient(30deg, #3C8CE7 , #00EAFF);*/
  /*border-radius: 0 10px 10px 0;*/
  overflow: hidden;
}

.tab-wrap .tab-li {
  text-align: center;
  border-bottom: 1px solid #e6e6e6;
  padding: 0 8px;
  cursor: pointer;
  transition: all .3s ease;
  background: #f2f4f9;
}

.tab-wrap .tab-li:hover {
  background: #b8babe;
}

.tab-wrap .tab-li.on, .tab-wrap .tab-li.on:hover {
  color: #FFFFFF;
  background: linear-gradient(30deg, #3C8CE7, #00EAFF);
}

.tab-wrap .tab-li:last-child {
  border: none;
}

.tab-wrap .tab-li .iconfont {
  font-size: 30px;
}

.tab-wrap .tab-li .tab-name {
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;
}

.tab-content {
  padding: 0 10px 0 10px;
}

.list-box .list {
  width: auto;
  min-width: 220px;
  flex: 1;
}

.list-box .list.hidden {
  height: 1px;
  margin: 0 10px;
}

@keyframes labels {
  0% {
    top: 0;
  }
  25% {
    top: -14px;
  }
  50% {
    top: 0;
  }
  75% {
    top: 14px;
  }
  100% {
    top: 0;
  }
}

.zxw-nav {
  /*background: linear-gradient(90deg, #35bce2, #409eff);*/
  background: white;
  line-height: 2.4rem;
  width: 100%;
  outline: none;
}

.zxw-nav .wrap {
  width: 1300px;
  margin: 0 auto;
}

.zxw-nav li {
  width: 7rem;
  color: #404040;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

.zxw-nav li:hover,
.zxw-nav li.on {
  background: #69c2ff;
  font-size: 15px;
  color: white;
}

.pop-aside-wrap {
  height: 415px;
  /*padding-right: 50px;*/
  position: relative;
  overflow: hidden;
  /*line-height: 32px;*/
  transition: all .3s;
}

.pop-aside-wrap-expand {
  height: calc(100vh - 190px - 24px);
  /*overflow-y: auto;*/
  padding-bottom: 24px;
}

.el-popper[x-placement^="right"] {
  margin-left: 0;
  border-radius: 0;
  padding-left: 20px;
}

.menu-box .tit {
  color: white;
  width: 200px;
  line-height: 2.4rem;
  padding-left: 1.4rem;
  background: #409eff;
  border-radius: 10px 10px 0 0;
  /*margin-top: -2.4rem;*/
  cursor: pointer;
}

.menu-box {
  position: absolute;
  top: 50px;
  z-index: 1999;
  background: rgba(255, 255, 255, .95);
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.menu-box .menu {
  width: 200px;
  height: 408px;
  opacity: 1;
  transform: scale(1, 1);
  transform-origin: top left;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.menu-box .menu.hide {
  height: 0;
  width: 0;
  transform: scale(0, 0);
  opacity: 0;
}

.menu-box .menu > div {
  /*min-height: 452px;*/
}

.menu .tab .iconfont {
  font-size: 20px;
  width: 26px;
  margin-right: 6px;
  font-weight: normal;
}

.menu .tab-box {
  padding-top: 10px;
  padding-left: 0;
}

.menu .tab-box .tab {
  height: 48px;
  cursor: pointer;
  font-size: 16px;
  color: #6d6d6d;
  width: 100%;
  padding: 0 5px 0 13px;
  position: relative;
}

.menu .tab-box .tab:hover,
.menu .tab-box .tab.on {
  /*color: #409eff;*/
  background: #ffffff;
}

.menu .tab-box .tab:hover:before,
.menu .tab-box .tab.on:before {
  /*color: #409eff;*/
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 42px;
  width: 100%;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.1);
  /*background: #ffffff;*/
  z-index: -1;
}

.menu-pop {
  height: 452px;
  position: absolute;
  left: 199px;
  top: 0;
  width: 200px;
  background: #fff;
  /*min-width: 200px !important;*/
  /*max-width: 750px !important;*/
  border-radius: 4px;
  border: 1px solid #ebeef5;
  padding: 12px;
  z-index: -1;
  color: #606266;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  transition: width .3s ease-in-out;
}

.menu-pop.large-type {
  width: 880px;
  transition: width .3s ease-in-out;

}

.menu-pop-expand {
  height: calc(100vh - 190px);
  padding: 12px 12px 42px 12px;
}

.box-shadow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.pop-aside-wrap .pop-title {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  cursor: pointer;
}

.pop-aside-wrap .pop-title:hover {
  color: #409eff;
}

.pop-aside-wrap .second-list {
  cursor: pointer;

}

.pop-aside-wrap .second-wrap {
  /*flex-wrap: nowrap;*/
}

.pop-aside-wrap .second-list:hover {
  color: #409eff;
}

.menu .icon-jiantou-you {
  font-size: 12px !important;
}

/*服务贴边栏样式*/
.menu-box.fixed-left {
  position: fixed;
  left: 40px;
  top: 140px;
  border-radius: 0 10px 10px 10px;
}

.menu-box.fixed-left .el-icon-menu {
  margin-right: 6px;
  margin-bottom: 6px;
  margin-top: 10px;
}

.menu-box.fixed-left .tit {
  position: absolute;
  left: -40px;
  width: 40px;
  height: 200px;
  padding: 8px;
  flex-direction: column;
  line-height: 1.2rem;
  border-radius: 0;
}

.expand-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 42px;
  line-height: 42px;
  background: #fff;
  opacity: 0.9;
  text-align: center;
  cursor: pointer;
  color: #409eff;
}

.expand-btn:hover {
  opacity: 0.8;
}

.suspension {
  position: fixed;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  top: 140px;
  right: 100px;
  z-index: 2000;
  cursor: pointer;
  text-align: center;
  line-height: 75px;
  font-size: 16px;
  color: #409eff;
  background: rgba(0, 0, 0, 0.2);
}

/*需求弹框覆盖样式*/
.el-dialog /deep/ .el-dialog__footer {
  padding-top: 0;
}

.el-dialog /deep/ .el-dialog__header {
  padding-bottom: 0;
}

.el-form /deep/ .el-button {
  width: 150px;
  transform: translateX(-40px);
}

.icon-svg-menu {
  width: 24px;
  margin-right: 5px;
  text-align: center;
  font-size: 16px;
  color: inherit !important;
  transition: inline-block .3s, left .3s, width .3s, margin-left .3s, font-size .3s;
}

.zxw-nav >>> .el-scrollbar__wrap {
  overflow-x: hidden !important;
}

.banner2 {
  height: 44vh;
}

.banner2 .wrap {
  width: 80%;
  margin: 0 auto;
  height: 100%;
}

.container {
  width: 80%;
  background: white;
  margin: -30px auto 0 auto;
  border-radius: 14px;
  min-height: 40vh;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.nav-item {
  margin: 0 20px;
}

.nav-item > .iconfont {
  font-size: 20px;
  margin-right: 10px;
}

.form-box {

  transform: translateX(-50%);
  background: rgba(255, 255, 255, .4);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  box-shadow: 0 0 30px rgba(0, 0, 0, .1);
  border-radius: 14px;
  margin: 0 auto;
  padding: 20px 30px;
}

/*.form-box {*/
/*  max-width: 600px;*/
/*  padding-top: 20px;*/
/*  margin: 0 auto;*/
/*}*/
@media only screen and (max-width: 1600px) {
  .detail-info .address {
    width: 100%;
  }

  .tab-wrap .tab-li .tab-name {
    font-size: 14px;
    margin-top: 6px;
  }
}

/* Dialog对话框 公共部分样式 Start*/
/*蒙层部分*/
.commonDialog {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 101;
  overflow: hidden;
}

/*框体部分*/
.commonDialog .content {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 9px;
  box-shadow: 2px 3px 5px #bbb;
  overflow: hidden;
  padding-bottom: 25px;
  margin-bottom: 30px;
  max-height: 90%;
  width: 1000px;
}

/*head 部分*/
.commonDialog .content .head {
  height: 44px;
  line-height: 44px;
  background: #efefef;
  width: 100%;
  text-align: center;
  color: #ff490b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}

/*内容区部分*/
.commonDialog .content .main {
  width: 100%;
  max-height: 70vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  margin-bottom: 5px;
}

.el-dialog, .el-message-box {
  border-radius: 9px;
}

.el-dialog__headerbtn, .el-message-box__headerbtn {
  /* top: 5px !important;
   right: 10px;*/
  font-size: 18px;
}

.el-icon-close {
  color: #666 !important;
}

.el-dialog__header, .el-message-box__header {
  margin: 1rem;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  justify-content: space-between;
  align-items: center;

}

.el-dialog__title, .el-message-box__title {
  margin: 1rem;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #0ea2fd;
}

.coverage-release .el-dialog__body {
  padding: 20px;
}

/* Dialog对话框 公共部分样式 End*/
.steps > div {
  margin-bottom: 8px;
  color: #03d39e;
}

.steps > div.doing {
  color: #292929;
}

.steps > div.fail {
  color: #ff5b00;
}

.steps i {
  margin-right: 10px;
}

.text-shenglue {
  width: 12vw;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.newVec {
  cursor: pointer;
  font-weight: bold;
  color: #409EFF;
}

/*.newVec:hover {*/
/*    font-size: 18px;*/
/*}*/
  .area {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 20px;
    margin-left: 15px;
    color: white;
  }
.area1 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 10px;
  margin-left: 15px;
  color: white;
  margin-top: -5px;
}
</style>
